import type { AuthCheckStatus } from '@core/types'
import type { RouteLocationNormalized, RouteRecordName } from '#vue-router'

const ALWAYS_ALLOWED_ROUTES: RouteRecordName[] = [
    'om-os',
    'kundeservice',
    'ansvarligt-spil',
    'privatlivspolitik',
    'betingelser',
    'affiliate',
    'presse',
    'FAQ',
    'Blog',
]

function canAccess(to: RouteLocationNormalized, onDeny: () => any | Promise<any>) {
    // Skip guard for always allowed routes
    if (to.name && ALWAYS_ALLOWED_ROUTES.includes(to.name)) return

    return onDeny()
}

export default defineNuxtRouteMiddleware((to) => {
    const { errors: { contactSupport } } = useAppConfig()
    const { status } = useAuthSync()
    const notifications = useNotifications()

    if (to.query.mitid_success === '0' && to.query.app === '1') {
        notifications.add({
            type: 'mitid',
            alert: {
                type: 'danger',
                closeable: true,
                message: to.query.mitid_error as string | undefined ?? contactSupport,
            },
        })
    }

    const statusRaw = toValue<AuthCheckStatus | undefined>(status)
    if (!statusRaw) return

    const dayjs = useDayjs()

    if (statusRaw.type === 'block') {
        let message = ''

        if (statusRaw.value.block.indefinite) {
            message = 'Din konto er spærret på ubegrænset tid.'
        }
        else {
            const endtime = dayjs.tz(statusRaw.value.block.endtime).format('DD.MM.YYYY HH:mm:ss')
            message = `Din konto er spærret indtil ${endtime}`
        }

        notifications.add({
            type: 'block',
            alert: {
                type: 'danger',
                closeable: true,
                message,
            },
        })

        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }

    if (statusRaw.type === 'deactivated') {
        notifications.add({
            type: 'deactivated',
            alert: {
                type: 'danger',
                closeable: true,
                message: 'Din konto er deaktiveret.',
            },
        })

        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }

    if (statusRaw.type === 'cooldown') {
        const endtime = dayjs.tz(statusRaw.value.cooldown.endtime).format('DD.MM.YYYY HH:mm:ss')
        const message = `Din bruger er på nedkøling indtil ${endtime}`

        notifications.add({
            type: 'cooldown',
            alert: {
                type: 'danger',
                closeable: true,
                message,
            },
        })

        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }

    if (statusRaw.type === 'suspended') {
        notifications.add({
            type: 'suspended',
            alert: {
                type: 'danger',
                closeable: true,
                message: 'Din spilkonto er suspenderet, og du kan derfor ikke spille eller foretage ind- og udbetalinger.',
            },
        })

        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }

    if (statusRaw.type === 'ssn-verification') {
        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }

    if (statusRaw.type === 'user-session-expired') {
        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }

    if (statusRaw.type === 'oces-login-required') {
        return canAccess(to, () => navigateTo(statusRaw.value.redirectUrl, { external: true }))
    }
})
